import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
	state: {
		email: '',
		sub: false,
		subTime: 'none',
		api: '/api/',
		version: 'Beta v.0.4.0',
	},
	getters: {
		getEmail: state => {
			return state.email
		},
		getSub: state => {
			return state.sub
		},
		getSubTime: state => {
			return state.subTime
		},
		getAPI: state => {
			return state.api
		},
		getVersion: state => {
			return state.version
		},
	},
	mutations: {
		setEmail: (state, email) => {
			state.email = email
		},
		setSub: (state, sub) => {
			state.sub = sub
		},
		setSubTime: (state, subTime) => {
			state.subTime = subTime
		},
	},
	actions: {
		updateEmail: ({ commit }, email) => {
			commit('setEmail', email)
		},
		updateSub: ({ commit }, sub) => {
			commit('setSub', sub)
		},
		updateSubTime: ({ commit }, subTime) => {
			console.log(subTime)
			let dateObj = new Date(subTime * 1000)
			const year = dateObj.getFullYear()
			const month = dateObj.getMonth() + 1 // Месяц начинается с 0, поэтому добавляем 1
			const day = dateObj.getDate()
			let formattedDate = `${day}.${month < 10 ? '0' + month : month}.${year}`
			commit('setSubTime', formattedDate)
		},
	},
	modules: {},
	plugins: [
		createPersistedState({
			paths: ['email', 'sub', 'subTime'],
		}),
	],
})
