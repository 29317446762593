import { createRouter, createWebHashHistory } from 'vue-router'
import jwt_decode from 'jwt-decode'

const isAuthenticated = () => {
	const token = $cookies.get('token')

	if (token) {
		try {
			const decoded = jwt_decode(token)
			if (
				decoded.exp &&
				decoded.exp > Math.floor(Date.now() / (1000 * 60 * 60 * 24))
			) {
				return true
			}
		} catch (error) {
			$cookies.remove('token')
		}
	}
	return false
}

const routes = [
	{
		path: '/',
		name: 'auth',
		component: () => import('../views/AuthView'),
	},
	{
		path: '/rules',
		name: 'rules',
		component: () => import('../views/RulesView'),
	},
	{
		path: '/privacypolicy',
		name: 'ppolicy',
		component: () => import('../views/PpolicyView'),
	},
	{
		path: '/terms',
		name: 'terms',
		component: () => import('../views/TermsView'),
	},
	{
		path: '/requisites',
		name: 'requisites',
		component: () => import('../views/RequisitesView'),
	},
	{
		path: '/dashboard',
		name: 'dash',
		meta: {
			requiresAuth: true,
		},
		component: () => import('../views/DashView'),
	},
	{
		path: '/catalog',
		name: 'catalog',
		meta: {
			requiresAuth: true,
		},
		component: () => import('../views/CatalogView'),
	},
	{
		path: '/create-bot',
		name: 'createbot',
		meta: {
			requiresAuth: true,
		},
		component: () => import('../views/CreateBotView'),
	},
	{
		path: '/chat/:botId/:chatID',
		name: 'chat',
		meta: {
			requiresAuth: true,
		},
		component: () => import('../views/ChatView.vue'),
	},
	{
		path: '/img/:botId',
		name: 'img',
		meta: {
			requiresAuth: true,
		},
		component: () => import('../views/ImgView.vue'),
	},
	{
		path: '/account',
		name: 'account',
		meta: {
			requiresAuth: true,
		},
		component: () => import('../views/AccManageView.vue'),
	},
	{
		path: '/subscription',
		name: 'subscription',
		meta: {
			requiresAuth: true,
		},
		component: () => import('../views/SubScriptionView.vue'),
	},
	{
		path: '/help',
		name: 'help',
		meta: {
			requiresAuth: true,
		},
		component: () => import('../views/HelpFeed.vue'),
	},
]

const router = createRouter({ history: createWebHashHistory(), routes })

router.beforeEach((to, from, next) => {
	const isAuthRequired = to.matched.some(record => record.meta.requiresAuth)

	if (isAuthRequired && !isAuthenticated()) {
		next('/')
	} else if (to.path === '/' && isAuthenticated()) {
		next('/dashboard')
	} else {
		next()
	}
})

export default router
