import { createApp } from "vue";
import VueCookies from "vue-cookies";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAnimXyz from "@animxyz/vue3";
import "@animxyz/core";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "@/assets/css/style.less";
import 'highlight.js/styles/tomorrow-night-bright.css';
import './registerServiceWorker'

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.addEventListener('updatefound', () => {
      const newWorker = registration.installing;

      newWorker.addEventListener('statechange', () => {
        if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
          // Когда новый сервис-воркер установлен и готов, отправьте ему сообщение
          navigator.serviceWorker.controller.postMessage({
            type: 'SKIP_WAITING'
          });
        }
      });
    });
  });
}

createApp(App)
  .use(store)
  .use(router)
  .use(VueAnimXyz)
  .use(VueCookies, { expires: "7d" })
  .mount("#app");
